
const credentials = {
  'authenticationAdvantages': {
    "zh": [{
      "iconName": "authentication",
      "advantagesTitle": "权威的认证",
      "advantagesContent": "云和恩墨资深数据库专家联手，打造权威认证"
    }, {
      "iconName": "system",
      "advantagesTitle": "完善的体系",
      "advantagesContent": "CA、CP、CE三级认证，层次清晰，构建"
    }, {
      "iconName": "curriculum",
      "advantagesTitle": "系统的课程",
      "advantagesContent": "课程内容由浅入深，循序渐进，紧跟版本变化"
    }, {
      "iconName": "lecturer",
      "advantagesTitle": "专业的讲师",
      "advantagesContent": "权威专家结合多年教学实践经验，提供专业培"
    }, {
      "iconName": "training",
      "advantagesTitle": "灵活的参训",
      "advantagesContent": "线上线下相结合，打破空间约束，灵活参与培"
    }, {
      "iconName": "prospect",
      "advantagesTitle": "可观的前景",
      "advantagesContent": "生态运营成熟，产业覆盖全面，市场未来可期"
    }],
    "en": [{
      "iconName": "authentication",
      "advantagesTitle": "Authoritative certification",
      "advantagesContent": "Yunhe Enmo senior database experts join forces to create authoritative certification"
    }, {
      "iconName": "system",
      "advantagesTitle": "Well-established system",
      "advantagesContent": "CA, CP, CE three-level certification, clear hierarchy, build a perfect system"
    }, {
      "iconName": "curriculum",
      "advantagesTitle": "Systematic courses",
      "advantagesContent": "The course content starts from easy to deep, step by step, keeping up with the version changes"
    }, {
      "iconName": "lecturer",
      "advantagesTitle": "Professional Instructors",
      "advantagesContent": "Authoritative experts combine years of practical experience in teaching to provide professional "
    }, {
      "iconName": "training",
      "advantagesTitle": "Flexible participation in training",
      "advantagesContent": "Combining online and offline, breaking the space constraint, flexible participation in training "
    }, {
      "iconName": "prospect",
      "advantagesTitle": "Promising prospects",
      "advantagesContent": "Mature ecological operation, comprehensive industrial coverage, promising market future"
    }]
  },
  'authenticationLevel': {
    "zh": [{
      "levelEn": "MGCA",
      "levelZh": "初级",
      "moudle": "考试模块",
      "note": "说明",
      "moduleContent": 'MGCA-010',
      "noteContent": "面向MogDB数据库的初级管理员、应用开发人员涵盖数据库系统概论，国产数据库发展业态，openGauss与MogDB数据库的关系，MogDB数据库简介、新特性、安装、客户端使用，MogDB体系概述，运维管理基础，数据库逻辑结构，SQL语言初探等。"
    }, {
      "levelEn": "MGCP",
      "levelZh": "中级",
      "moudle": "考试模块",
      "note": "说明",
      "moduleContent": 'MGCP-020',
      "noteContent": "面向MogDB数据库的中级管理员、架构设计与优化人员涵盖MogDB的生态发展、深入解析体系架构、深度维护、数据管理、功能特性、安全管理、性能优化、高级SQL、备份恢复、高可用技术以及相关管理工具等。"
    }, {
      "levelEn": "MGCE",
      "levelZh": "高级",
      "moudle": "考试模块",
      "note": "说明",
      "moduleContent": '敬请期待…',
      "noteContent": "面向MogDB数据库的架构师、高级管理员、安全管理员以及AI方向的数据库使用者涵盖数据库高级安全管控、数据库深度诊断与优化、人工智能、负载均衡、分布式架构等。"
    }],
    "en": [{
      "levelEn": "MGCA",
      "levelZh": "Primary",
      "moudle": "Exam Modules",
      "note": "Description",
      "moduleContent": 'MGCA-010',
      "noteContent": "For junior administrators, application developers of MogDB database. Covering the introduction of database system, the development of domestic database, the relationship between openGauss and MogDB database, the introduction of MogDB, new features, installation, client use, the overview of MogDB system, the basis of operation and maintenance management, the logical structure of database, the first exploration of SQL language, etc."
    }, {
      "levelEn": "MGCP",
      "levelZh": "Intermediate",
      "moudle": "Exam Modules",
      "note": "Description",
      "moduleContent": 'MGCP-020',
      "noteContent": "For mid-level administrators, architecture designers and optimizers of MogDB databases. Covering the ecological development of MogDB, in-depth analysis of the architecture, in-depth maintenance, data management, functional features, security management, performance optimization, advanced SQL, backup and recovery, high availability technology and related management tools."
    }, {
      "levelEn": "MGCE",
      "levelZh": "Advanced",
      "moudle": "Exam Modules",
      "note": "Description",
      "moduleContent": 'Coming soon',
      "noteContent": "For MogDB database architects, senior administrators, security administrators and database users in the direction of AI. Covering advanced database security control, database deep diagnosis and optimization, artificial intelligence, load balancing, distributed architecture, etc."
    }]
  },
  'roadmaps': {
    "zh": [{
      "iconName": "train",
      "title": "01.预约培训",
    }, {
      "iconName": "join",
      "title": "02.参加培训",
    }, {
      "iconName": "polling",
      "title": "03.考场查询",
    }, {
      "iconName": "examination",
      "title": "04.预约考试",
    }, {
      "iconName": "a-takeatest",
      "title": "05.参加考试",
    }, {
      "iconName": "certificate",
      "title": "06.获取证书",
    }, {
      "iconName": "query",
      "title": "07.证书查询下载",
    }],
    "en": [{
      "iconName": "train",
      "title": "01.Book training",
    }, {
      "iconName": "join",
      "title": "02.Attend training",
    }, {
      "iconName": "polling",
      "title": "03.Exam hall inquiry",
    }, {
      "iconName": "examination",
      "title": "04.Book examination",
    }, {
      "iconName": "a-takeatest",
      "title": "05.Take the exam",
    }, {
      "iconName": "certificate",
      "title": "06.Get certificate",
    }, {
      "iconName": "query",
      "title": "07.Certificate download",
    }]
  },
  'mentoring': {
    "zh": [{
      "question": "1、恩墨颁发的MogDB认证证书是市面上唯一的MogDB认证证书吗？",
      "answer": "答：是的，MogDB是基于openGauss研发的商用数据库，由云和恩墨负责数据库的内核与生态工具研发、生态推广、人才培养，云和恩墨希望通过规范的组织与专业的培训考试，让openGauss的爱好者能够不仅仅掌握openGauss开源数据库的特性，更能够掌握基于openGauss研发的商用数据库MogDB的增强能力和生态全栈运维体系，通过系统的学习与掌握相关技能，成为企业可信的openGauss与MogDB 双库技术人才。"
    }, {
      "question": "2、MogDB认证证书有纸质证书吗？",
      "answer": "答：没有。目前电子证书已经成为认证行业的趋势，相对纸质证书而言，存在携带方便和安全防伪（可线上查询下载）的便利性，因此MGCA将采用电子证书发放。在通过认证考试后，3天内将会收到电子版证书，如未收到，可联系教务老师，或在7天后自行在证书查询页面查询和下载证书。"
    }, {
      "question": "3、不参加培训是否可以直接参加MGCA、MGCP考试？",
      "answer": "答：可以，但为了确保学员能够体系的掌握openGauss与MogDB的相关技能，成为真正的技术者而非应试者，建议系统的学习后再进行考试。"
    }, {
      "question": "4、可以不考 MGCA，直接考 MGCP 或者 MGCE 吗？",
      "answer": "答：MogDB数据库有大量的技术创新，且数据库将会是企业信息化的核心，其维护不容忽视。我们希望学员在拥有扎实基础后再进行深入的学习，从而更为体系的掌握和提升数据库技能，因此必须具备 MGCA 后方可获得后续的认证，也就是说，必须逐级考试，不允许跨级考试。"
    }, {
      "question": "5、认证证书有有效期么？",
      "answer": "答：认证证书终身有效。但MogDB认证是跟随MogDB软件版本发布的，随着MogDB不断更新发布新的软件版本，社区也将每隔一段时期，同步发布新的MogDB版本的认证，已通过认证的学员可以选择重认证模式快速获取新版本的认证，新版本证书获取可基于旧版本的基础证书，即如果已获得旧版MGCA，可直接参加新版MGCP培训/考试。"
    }, {
      "question": "6、如何参加培训？",
      "answer": "答：云和恩墨拥有业内知名的专业数据库培训机构恩墨学院，为学员提供专业的讲师、课程体系以及技能考试辅导，确保学员在获得技能的同时，可以得到相应的资质证书。"
    }, {
      "question": "7、已获得openGauss OGCA、OGCP证书，是否对MogDB MGCA、MGCP证书获取有帮助？",
      "answer": "答：MogDB是在openGauss内核的基础上研发的商用版本，其数据库运维管理具有一定的相似性，获得OGCA、OGCP证书的人员已经掌握了openGauss运维的相关知识，也即具备了MogDB运维的部分能力，能够更快掌握MogDB的运维管理。为了促使拥有openGasuss能力的人员更快获得MogDB的相关资质，特推出促销政策，拥有OGCA证书的相关人员，可凭证书缴付200元参加MGCA的3天培训与考试，从而获得MGCA证书。"
    }],
    "en": [{
      "question": "1. Is the MogDB certification issued by Yunhe Enmo the only MogDB certification in the market?",
      "answer": "Answer:Yes. MosDB is a commercial database developed based on openGauss. and Yunhe Enmo is responsible for the database kernel and ecological tool development, ecological promotion and talent cultivation. Yunhe Enmo hope that through standardized organization and professional training exams, openGauss fans can not only master the features of openGauss open source database, but also master the enhanced capabilities and ecological full-stack operation and maintenance system based on openGauss. Through systematic learning and mastering related skills, they can become credible technical talents of openGauss and MogDB."
    }, {
      "question": "2. Does the MogDB certification have a paper certificate?",
      "answer": "Answer:No. Digital certificates have become the trend of the certification industry currently. Compared with paper certificates, digital certificates are more convenient to carry, secure, and counterfeit-proof (online query and download). Therefore, MGCA will issue digital certificates. After passing the certification examination, you will receive an digital certificate within 3 days. If you do not receive the certificate, contact the related teacher or download the certificate from the certificate enquiry page after 7 days."
    }, {
      "question": "3. Can I take the MGCA and MGCP exams directly without attending the training?",
      "answer": "Answer:Yes. but in order to ensure that students can systematically master the skills related to openGauss and MogDB and become a real technician rather than a test taker, it is recommended to study systematically before taking the exam."
    }, {
      "question": "4. Can I take the MGCP or MGCE directly without taking the MGCA?",
      "answer": "Answer:MogDB database has a lot of technical innovation, and the database will be the core of enterprise information technology, its maintenance should not be ignored. We hope that students can have a solid foundation and then learn in depth, so that they can master and improve database skills more systematically, so they must have MGCA before they can obtain subsequent certifications, that is to say, they must take the exams step by step and are not allowed to cross the exams."
    }, {
      "question": "5. Does the certification have a validity period?",
      "answer": "Answer:The certification is valid for life. But MogDB certification is released following MogDB software version, with MogDB constantly releasing new software version, the community will also release the certification of new MogDB version every period of time, the certified students can choose the re-certification mode to get the new version of certification quickly, the new version of the certificate can be obtained based on the old version of the basic certificate, that is, if the old version has been obtained MGCA, you can directly participate in the new version of MGCP training exam."
    }, {
      "question": "6. How to participate in the training?",
      "answer": "Answer:Yunhe Enmo has a well-known professional database training institution in the industry - Enmo College, which provides students with professional instructors, course system and skill test coaching to ensure that students can get the corresponding qualification certificate while acquiring skills."
    }, {
      "question": "7. I have obtained the openGauss OGCA and OGCP certificates. Is it helpful to obtain the MogDB MGCA and MGCP certificates?",
      "answer": "Answer:MogDB is a commercial version developed on the basis of openGauss kernel, and its database operation and maintenance management has certain similarity. Those who have obtained OGCA and OGCP certificates have mastered the knowledge related to openGauss operation and maintenance, that is, they have part of the ability of MogDB operation and maintenance, and can master the operation and maintenance management of MogDB more quickly. In order to promote the personnel who have the ability of openGasuss to get the relevant qualification of MogDB faster, we launch the promotion policy, the relevant personnel who have the certificate of OGCA can pay 200 RMB to attend the 3-day training and examination of MGCA with the certificate, so as to get the certificate of MGCA."
    }]
  }
}
const mRoadmaps = {
  'firstRow': {
    "zh": [{
      "iconName": "train",
      "title": "01.预约培训",
    }, {
      "iconName": "join",
      "title": "02.参加培训",
    }, {
      "iconName": "polling",
      "title": "03.考场查询",
    }],
    "en": [{
      "iconName": "train",
      "title": "01.Book training",
    }, {
      "iconName": "join",
      "title": "02.Attend training",
    }, {
      "iconName": "polling",
      "title": "03.Exam hall inquiry",
    }]
  },
  'secondRow': {
    "zh": [{
      "iconName": "certificate",
      "title": "06.获取证书",
    }, {
      "iconName": "a-takeatest",
      "title": "05.参加考试",
    }, {
      "iconName": "examination",
      "title": "04.预约考试",
    }],
    "en": [{
      "iconName": "certificate",
      "title": "06.Get certificate",
    }, {
      "iconName": "a-takeatest",
      "title": "05.Take the exam",
    }, {
      "iconName": "examination",
      "title": "04.Book examination",
    }]
  },
  'thirdRow': {
    "zh": [{
      "iconName": "query",
      "title": "07.证书查询下载",
    }],
    "en": [{
      "iconName": "query",
      "title": "07.Certificate download",
    }]
  }
}
module.exports = {
  credentials,
  mRoadmaps
}
