import React from "react"
import { FormattedMessage } from "react-intl"
import { Collapse } from 'antd'

import Seo from '../components/seo'
import Icon from '../components/iconfontCpt'
import Layout from "../components/layout"
import { credentials, mRoadmaps } from '../utils/json/credentials'

import '../style/trainingCertification.scoped.scss'

const trainingCertification = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? 'en' : 'zh'

  const { Panel } = Collapse
  
  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
      <Seo title="MogDB: Training and Certification"/>
      <div className="w-banner-container">
        <div className="w-banner-box"><div className="w-banner-text"><FormattedMessage id="trainingCertification.pageTitle" /></div></div>
      </div>
      <div className={`certification-container ${pageLang}`}>
        <div className="overview-container">
          <div className="content">
            <div className="title"><FormattedMessage id="trainingCertification.bannerTitle" /></div>
            <div className="text">
              <p className="first-text"><FormattedMessage id="trainingCertification.bannerContent1" /><span className="marker"><FormattedMessage id="trainingCertification.bannerContent2" /></span></p>
              <p><FormattedMessage id="trainingCertification.bannerContent3" /></p>
            </div>
          </div>
          <div className="img"></div>
        </div>
        <i></i>
        <div className="advantage-container">
          <div className="title"><FormattedMessage id="trainingCertification.authenticationAdvantages" /></div>
          <div className="a-content">
            {credentials.authenticationAdvantages[pagePrefix].map((item, idx) => (
              <div className="advantage-box" key={idx}>
                <Icon type={`icon-${item.iconName}`} size={28}/>
                <div className="a-title">{item.advantagesTitle}</div>
                <div className="a-content">{item.advantagesContent}</div>
              </div>
            ))}
          </div>
        </div>
        <i className="a-line"></i>
        <div className="level-container">
          <div className="title"><FormattedMessage id="trainingCertification.authenticationLevel" /></div>
          <div className="l-content">
            {credentials.authenticationLevel[pagePrefix].map((m, i) => (
              <div className={`level-box ${pagePrefix === 'en' ? 'en-text' : ''}`} key={i}>
                <div className={`text level-${i}`}>
                  <p>{m.levelEn}<span>{m.levelZh}</span></p>
                </div>
                <div className="table">
                  <div className="t-title">
                    <div>{m.moudle}</div>
                    <div>{m.note}</div>
                  </div>
                  <div className="t-content">
                    <div>{m.moduleContent}</div>
                    <div>{m.noteContent}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="download">
            <p><FormattedMessage id="trainingCertification.download1" /><a href="https://cdn-mogdb.enmotech.com/website/MGCA%E8%AF%BE%E7%A8%8B%E4%BB%8B%E7%BB%8D.pdf" target="_blank"><Icon type="icon-download" size={12}></Icon></a></p>
            <p><FormattedMessage id="trainingCertification.download2" /><a href="https://cdn-mogdb.enmotech.com/website/MGCA%E8%80%83%E8%AF%95%E8%AF%B4%E6%98%8E.pdf" target="_blank"><Icon type="icon-download" size={12}></Icon></a></p>
          </div>
        </div>
        <div className="roadmap-contanier">
          <div className="title"><FormattedMessage id="trainingCertification.roadmap" /></div>
          <div className="r-content">
            {credentials.roadmaps[pagePrefix].map((r, index) => (
              <div className="r-box" key={index}>
                <div className="r-box-content">
                  <div className="line">
                    <Icon type={`icon-${r.iconName}`} size={75}/>
                    <i></i>
                  </div>
                  <div className={`r-title ${pagePrefix === 'en' ? 'r-en-title' : ''}`}>{r.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="m-roadmap-contanier">
          <div className="title"><FormattedMessage id="trainingCertification.roadmap" /></div>
          <div className="r-content">
            {mRoadmaps.firstRow[pagePrefix].map((r, index) => (
              <div className={`container ${pagePrefix === 'en' ? 'en-container' : ''}`}>
                <div className="r-box" key={index}>
                  <div className="r-box-content">
                    <Icon type={`icon-${r.iconName}`} size={40}/>
                    <div className={`r-title ${pagePrefix === 'en' ? 'r-en-title' : ''}`}>{r.title}</div>
                  </div>
                </div>
                <i></i>
              </div>
            ))}
            {mRoadmaps.secondRow[pagePrefix].map((r, index) => (
              <div className={`container ${pagePrefix === 'en' ? 'en-container' : ''}`}>
                <div className="r-box" key={index}>
                  <div className="r-box-content">
                    <Icon type={`icon-${r.iconName}`} size={40}/>
                    <div className={`r-title ${pagePrefix === 'en' ? 'r-en-title' : ''}`}>{r.title}</div>
                  </div>
                </div>
                <i></i>
              </div>
            ))}
            {mRoadmaps.thirdRow[pagePrefix].map((r, index) => (
              <div className={`container ${pagePrefix === 'en' ? 'en-container' : ''}`}>
                <div className="r-box last-item" key={index}>
                  <div className="r-box-content">
                    <div className="r-icon"><Icon type={`icon-${r.iconName}`} size={40}/></div>
                    <div className={`r-title ${pagePrefix === 'en' ? 'r-en-title' : ''}`}>{r.title}</div>
                  </div>
                </div>
                <i></i>
              </div>
            ))}
          </div>
        </div>
        <div className="mentoring-container">
        <div className="title"><FormattedMessage id="trainingCertification.mentoring" /></div>
          {credentials.mentoring[pagePrefix].map((m, indexs) => (
            <div className="mentoring-box" key={indexs}>
              <Collapse expandIconPosition="right">
                <Panel header={m.question} key={indexs}>
                  <p>{m.answer}</p>
                </Panel>
              </Collapse>
            </div>
          ))}
          {pagePrefix=== 'zh' &&
            <div className="tip"><FormattedMessage id="trainingCertification.notice" /><span>certification@enmotech.com</span><FormattedMessage id="trainingCertification.notice1" /></div>
          }
          {pagePrefix=== 'en' &&
            <div className="tip"><FormattedMessage id="trainingCertification.notice" /><span>certification@enmotech.com</span></div>
          }
        </div>
      </div>
    </Layout>
  )
}
export default trainingCertification